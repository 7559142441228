.title {
  margin-left: 50px;
}

.navbar {
  background-color: #044594;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.menuBars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  margin-right: 17px;
}
.navMenu {
  background-color: #044594;
  width: 310px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  opacity: 0.96;
  z-index: 99;
}

.navMenu.active {
  left: 0;
  transition: 350ms;
}

.navText {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.navText a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.navText a:hover {
  background-color: #1a83ff;
}

.navMenuItems {
  width: 100%;
}

.navbarToggle {
  background-color: #044594;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}
