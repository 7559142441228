body {
  margin: 0px;
  height: 100vh;
}

#root {
  height: 100%;
  width: 100%;
}
.map {
  height: 100%;
  width: 100%;
}

.leaflet-container {
  width: 100%;
  height: 500px;
  z-index: 0;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  /* こうしないとなぜか「＋ー」が右に寄る */
  text-indent: -14px;
}

.leaflet-popup-close-button {
  /* こうしないとなぜか「x」が右に寄る */
  padding-right: 32px;
}

.cell-search-highlight {
  background-color: lightgreen;
  margin: 0px;
}
